<template>
<v-layout>
    <v-list-item @click="$router.push('/app/expenses/view/'+expense._id)" three-line class="bottom-border">
        <v-list-item-content>
            <v-layout>
                <v-flex xs3>
                    <v-list-item-avatar :style="theme" size="50" tile>
                        {{date}}
                    </v-list-item-avatar>
                </v-flex>
                <v-flex xs8 my-4>
                    <v-list-item-title class="title">{{expense.narration}}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle">{{expense.mode}}</v-list-item-subtitle>
                </v-flex>
                <v-flex xs2 my-4>
                    <v-list-item-title class="align-card-chip">Rs {{expense.txAmount}}</v-list-item-title>
                </v-flex>
                <v-menu left>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                    <v-list>
                        <v-list-item>
                            <action-button key="Edit" icon="mdi-pencil" :show="isAllowed('editExpenses')" @click="$router.push('/app/expenses/'+expense._id)"></action-button>
                        </v-list-item>
                        <v-list-item>
                            <action-button key="Delete" icon="mdi-delete" :show="isAllowed('deleteExpenses')" @click="$emit('delete',expense._id)"></action-button>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-layout>
        </v-list-item-content>
    </v-list-item>
</v-layout>
</template>

<script>
import moment from 'moment'
export default {
    props: ["icons", "expense"],
    data() {
        return {
            date: '',
            year: '',
        }
    },
    mounted() {
        this.initcomponent();
    },
    methods: {
        initcomponent() {
            this.date = moment(this.expense.txDate).format('DD-MMM-YY')
            this.year = moment(this.expense.txDate).format('YYYY')
        },
        completeAction(icon, id) {
            if (icon.name == "mdi-delete")
                this.$emit('delete', id)
            else
                this.performAction(icon, id)
        }
    },
};
</script>

<style scoped>
.bottom-border{
    border-bottom: 1px solid gray;
}
.title{
    font-size: 18px !important;
    font-weight: 400;
}
.subtitle{
    font-size: 11px !important;
}
</style>
