<template>
<div>
    <div class="display-block">
        <alert-message v-if="msg" :error="msg"></alert-message>
        <page-header v-model="search" title="Expenses" permission="createExpenses" @click="navigateTo('/app/expenses/0')"></page-header>
    </div>
    <div>
        <v-data-table :headers="headers" :items="items" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
        :items-per-page="items.length" hide-default-footer class="elevation-1" :search="search">
            <template v-slot:item.txDate="{item}">
                {{item.txDate|dateFormat}}
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteExpense(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <expense-card :expense="item" :icons="icons" @delete="deleteExpense"></expense-card>
            </template>
        </v-data-table>
        <pagination :api="apiCall" v-model="items" sort="-txDate"></pagination>
    </div>
    <alert-message v-if="msg" :error="msg"></alert-message>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import ExpenseCard from '@/components/ExpenseResponsiveCard.vue'
export default {
    components: {
        ActionButton,
        ExpenseCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Expense Date",
                    value: "txDate"
                },
                {
                    text: "Mode",
                    value: 'mode'
                },
                {
                    text: "Type",
                    value: 'txType'
                },
                {
                    text: "Narration",
                    value: 'narration'
                },
                {
                    text: "Amount",
                    value: 'txAmount',
                    align: 'right'
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/expenses/view/",
                    permission: "viewExpenses"
                }, {
                    name: "mdi-pencil",
                    path: "/app/expenses/",
                    permission: "editExpenses"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteExpenses"
                }
            ],
            sortBy: 'txDate',
            sortDesc: true,
            apiCall:appConstants.EXPENSE_MANAGER_API
        };
    },
    methods: {
        async deleteExpense(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Expense?", appConstants.EXPENSE_MANAGER_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        }
    }
};
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
